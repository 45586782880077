<template>
  <div
    v-resize="handleResize"
    class="wrapper"
  >
    <v-skeleton-loader
      v-if="loading"
      :max-width="600"
      type="heading"
    />
    <div
      v-else
      class="heading__wrapper"
    >
      <h2 class="heading">
        {{ heading }}
        <span
          v-if="count"
          class="count"
        >({{ count }})
        </span>
      </h2>
      <a
        v-if="displaySeeAll"
        tabindex="0"
        class="see-all__link"
        @click="$emit('see:all', row)"
      >
        See all
      </a>
      <v-spacer v-else />
    </div>
    <div
      ref="slider-content__wrapper"
      class="content__wrapper"
      :style="{'min-width': '100%','margin-left': negativeMargin, 'margin-right': negativeMargin }"
      @click="$emit('set:rowClicked', row)"
    >
      <div
        v-if="isOverflowing && scrollPosition > 0 && $vuetify.breakpoint.smAndUp"
        class="prev-arrow__wrapper"
      >
        <v-btn
          fab
          color="primary"
          small
          @click="handleClick('prev')"
        >
          <v-icon large>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </div>
      <ul
        ref="slider-content__container"
        class="content__container"
        :style="{'padding': `0 ${margin - 12}px`}"
        @scroll="handleScroll"
      >
        <template v-if="loading">
          <li
            v-for="index in 8"
            :key="index"
          >
            <v-responsive
              v-if="loading"
              class="pa-3"
              :width="cardSize"
              :height="cardSize"
            >
              <v-skeleton-loader
                boilerplate
                type="image, list-item-three-line, actions"
              />
            </v-responsive>
          </li>
        </template>
        <template v-else>
          <li
            v-for="(resource, index) in resources"
            :key="index"
            class="pa-3"
          >
            <ResourceCollectionsCard
              v-if="isCollection(resource)"
              :item="resource"
              :size="cardSize"
              :height="380"
            />
            <ResourceCard
              v-else
              :item="resource"
              :size="cardSize"
              :height="380"
            />
          </li>
        </template>
      </ul>
      <div
        v-if="(!loading && isOverflowing && scrollPosition < endPosition && $vuetify.breakpoint.smAndUp) || (!loading && isOverflowing && endPosition === 0 && $vuetify.breakpoint.smAndUp)"
        class="next-arrow__wrapper"
      >
        <v-btn
          fab
          color="primary"
          small
          @click="handleClick('next')"
        >
          <v-icon large>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CreateNewResourceCard from '@/views/MyResourcesPage/CreateNewResourceCard';
import ResourceCard from '@/views/HubResourcesPage/ResourceCard';
import ResourceCollectionsCard from '@/components/Collection/CollectionCard';

export default {
  name: 'ScrollingCardsView',
  components: {
    ResourceCollectionsCard,
    ResourceCard,
    CreateNewResourceCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    count: {
      type: [Number, Boolean],
      default: false
    },
    heading: {
      type: String,
      default: ''
    },
    row: {
      type: String,
      default: ''
    },
    resources: {
      type: Array,
      default: () => []
    },
    displayCreateButton: {
      type: Boolean,
      default: false
    },
    displaySeeAll: {
      type: Boolean,
      default: false
    },
    negativeMargin: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      cardSize: this.$vuetify.breakpoint.smAndUp ? '370' : '300',
      scrollPosition: 0,
      endPosition: 0,
      wrapperWidth: 0
    }
  },
  computed: {
    margin() {
      const value = this.negativeMargin.substring(1, this.negativeMargin.length - 2);
      return Math.floor(Number(value));
    },
    contentWidth() {
      let count = this.resources.length;
      if (this.displayCreateButton) {
        count++;
      }
      return (Number(this.cardSize) + 24) * count - 24;
    },
    isOverflowing() {
      return this.contentWidth > this.wrapperWidth;
    }
  },
  watch: {
    $route() {
      setTimeout(() => {
        const el = this.$refs['slider-content__wrapper'];
        if (el) {
          this.wrapperWidth = el.offsetWidth;
        }
      }, 500);
      const containerEl = this.$refs['slider-content__container'];
      containerEl.scrollTo({
        left: this.scrollPosition,
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      const el = this.$refs['slider-content__wrapper'];
      if (el) {
        this.wrapperWidth = el.offsetWidth;
      }
    });
  },
  methods: {
    isCollection(r) {
      return r?.record?.isResourceCollection;
    },
    handleScroll(e) {
      const el = e.srcElement;
      this.scrollPosition = Math.ceil(el.scrollLeft);
    },
    handleClick(direction) {
      const containerEl = this.$refs['slider-content__container'];
      const maxScrollLeft = containerEl.scrollWidth - containerEl.clientWidth;
      const cardAndPaddingSize = Number(this.cardSize) + 24;
      let distanceToScroll;
      if (this.$vuetify.breakpoint.mdAndUp) {
        distanceToScroll = cardAndPaddingSize * 2;
      } else {
        distanceToScroll = cardAndPaddingSize;
      }
      let scrollPosition = this.scrollPosition;
      if (direction === 'prev') {
        if (scrollPosition - distanceToScroll < 0) {
          scrollPosition = 0;
        } else {
          scrollPosition = scrollPosition - distanceToScroll;
        }
      }
      if (direction === 'next') {
        if (scrollPosition + distanceToScroll > maxScrollLeft) {
          scrollPosition = maxScrollLeft;
        } else {
          scrollPosition = scrollPosition + distanceToScroll;
        }
      }

      this.endPosition = maxScrollLeft;
      containerEl.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });
    },
    handleResize() {
      setTimeout(() => {
        const el = this.$refs['slider-content__wrapper'];
        if (el) {
          this.wrapperWidth = el.offsetWidth;
        }
        const containerEl = this.$refs['slider-content__container'];
        if (containerEl) {
          this.endPosition = containerEl.scrollWidth - containerEl.clientWidth;
        }
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.wrapper {
  margin-top: 32px;

  .heading__wrapper {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .heading {
      font-size: 20px;
      padding-right: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .count {
        padding-left: 8px;
        color: $ads-dark-70;
        font-weight: normal;
        font-size: 16px;
      }
    }

    .see-all__link {
      text-decoration: underline;
      color: $ads-navy;

      &:focus {
        outline-color: $ads-navy;
      }
    }
  }

  .content__wrapper {
    min-width: 100%;
    position: relative;

    .prev-arrow__wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 60px;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .next-arrow__wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 60px;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .content__container {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow: auto;
      /* Next lines hides scrollbar in different browsers for styling purposes */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome opera and Safary */
        width: 0;
        background: transparent;
      }
    }
  }
}

.v-skeleton-loader {
  height: 100%;

  ::v-deep.v-skeleton-loader__image {
    height: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    .heading__wrapper {
      padding-left: 12px;

      .heading {
        font-size: 18px;
      }

      .see-all__link {
        padding-right: 12px;
      }
    }
  }
}
</style>
