import axios from 'axios';
import Vue from 'vue';
import { get, patch } from '@/api/http';

const CONFIG = (window as any).dlrhConfig;

export async function getCreatedResources(baseUrl, path, page, uid, body) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          uid,
          source: 'myresource'
        })
      }
    };
    // const res = await get(`${baseUrl}/resources/${path}&pagenum=${page}`, config);
    const res = await axios.post(`${baseUrl}/search${path}&page=${page}`, body, config);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getResources(baseUrl, {path, uid, page, source='myresource'}, body={}) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source,
          uid: uid
        })
      }
    };

    // const res = await get(`${baseUrl}/search${path}&pagenum=${page}`, config);
    const res = await axios.post(`${baseUrl}/search${path}&page=${page}`, body, config);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getResourceDetails(baseUrl, payload, fromLike, source='myresource') {
  const uid = payload.uid;
  const resourceId = payload.resourceId;
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source,
          uid: uid
        })
      }
    };
/*    let url = fromLike
      ? `${baseUrl}/resources/resource/${resourceId}?fromLike=true`
      : `${baseUrl}/resources/resource/${resourceId}`;*/
    // const url = fromLike
    //   ? `${baseUrl}/search?ItemID=${resourceId}?fromLike=true`
    //   : `${baseUrl}/search?ItemID=${resourceId}`;
    //const url = `${baseUrl}/search?record.id=${resourceId}`
    // can't use record.id as it's now a text field in Elastic to run analyzer for resource editor searching,
    // revert back to document.ItemID
    const url = `${baseUrl}/search?ItemID=${resourceId}`
    const res = await axios.post(url, {}, config);
    return res;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function createResource(baseUrl, requestData) {
  const uid = requestData.uid;
  const source = requestData.payload.source || 'myresource';
  const document = requestData.payload.document;
  const files = requestData.payload.files;
  const links = requestData.payload.links;
  const isDraft = requestData.payload.document.draft;
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'multipart/form-data',
          application: CONFIG.APPLICATION,
          source,
          version: 1,
          resourceId: document.ItemID,
          uid: uid
        })
      }
    };

    const bodyFormData = new FormData();

    // if (isDraft) {
    //   // draft
    //   bodyFormData.append('document', JSON.stringify(document));
    // } else {
    // document
    bodyFormData.append('document', JSON.stringify(document));
    // }

    // links
    if (links) {
      bodyFormData.append('links', JSON.stringify(links));
    }
    // files
    if (files && files[0]) {
      bodyFormData.append('files', files[0]);
    }

    let url = `${baseUrl}/resources/${uid}`
    if (isDraft) {
      // draft
      url = `${baseUrl}/resources/draft/${document.ItemID}`
    }
    return axios.post(url, bodyFormData, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function updateResource(baseUrl, requestData) {
  // const uid = requestData.uid;
  // const firstName = requestData.firstName;
  // const lastName = requestData.lastName;
  // const resourceId = requestData.resourceId;
  // const newDocument = requestData.newDocument;
  // const newLinks = requestData.newLinks;
  // const newFiles = requestData.newFiles;
  // const subResources = requestData.subResources;
  const { uid, firstName, lastName, resourceId, newDocument, newLinks, newFiles, subResources, published = true, application } = requestData
  const source = requestData.source || 'myresource';
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'multipart/form-data',
          application: application || CONFIG.APPLICATION,
          source,
          uid: uid,
          first_name: firstName,
          last_name: lastName
        })
      }
    };
    const bodyFormData = new FormData();
    let queries: string[] | string = [];
    // document
    published && bodyFormData.append('document', JSON.stringify(newDocument));
    // draft
    !published && bodyFormData.append('draft', JSON.stringify(newDocument));
    // links
    if (newLinks) {
      bodyFormData.append('links', JSON.stringify(newLinks));
    } else if (!newFiles) {
      queries.push('keepLinks=true');
    }

    if (subResources) {
      bodyFormData.append('subResources', JSON.stringify(subResources));
    } else {
      queries.push('keepSubResources=true');
    }

    // files
    if (newFiles && newFiles[0]) {
      bodyFormData.append('files', newFiles[0]);
    } else if (!newLinks) {
      queries.push('keepFiles=true');
    }
    // queries
    if (queries.length) {
      queries = '?' + queries.join('&');
    } else {
      queries = '';
    }

    return axios.put(
      `${baseUrl}/resources/resource/${resourceId}${queries}`,
      bodyFormData,
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function copyResource(baseUrl, requestData) {
  const uid = requestData.uid;
  const originalResourceId = requestData.originalResourceId
  const source = requestData.source || 'myresource';
  // leave myresource as is (lowercase) as it's a generated guid which are all lower case
  // for hub resources, convert them all to upper case
  const resourceId = source === 'myresource' ? requestData?.resourceId : requestData?.resourceId?.toString().toUpperCase();
  const action = requestData.action;
  const index = requestData.index;
  const sessionid = requestData.sessionid;
  const customIdQuery = requestData.customIdQuery

  let application = ''; // TODO: This needs to be fixed with a better way. OLP resources have an application as olp, we can't use CONFIG.APPLICATION
                        // and not sure why requestData.application is sending in urh by default? need to check where and how this is set
                        // we can use the audit.iss from the resource to obtain the application, this will ensure we get the right one
  if (requestData.source === 'public' || requestData.source === 'department') {
    application = 'olp';
  } else {
    application = requestData.application || CONFIG.APPLICATION;
  }

  //console.log('AAA: ' + JSON.stringify(requestData));

  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          //application: requestData.application || CONFIG.APPLICATION,
          application,
          source,
          uid: uid,
          sessionid: sessionid
        })
      }
    };

    // console.log(typeof index);
    let query = `&action=${action}`;
    if (typeof index === 'number') {
      query += `&index=${index}`;
    }else if(customIdQuery === true){
      query +=`&customId=${resourceId}`;
    }

    let url = '';
    if (source === 'myresource') {
      url = `${baseUrl}/resources/copy/${resourceId}?userId=${uid}${query}` // for myresources, all ids are generated guids and are all lower case
    }  else {
      url = `${baseUrl}/resources/copy/${originalResourceId}?userId=${uid}${query}`;
    }

    return axios.post(
      url,
      {},
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function finaliseCopyResource(baseUrl, requestData) {
  const uid = requestData.uid;
  const resourceId = requestData.resourceId;
  const source = requestData.source || 'myresource';
  const payload = {
    draft: requestData.modifiedDocument
  };
  const sessionid = requestData.sessionid;

  let application = ''; // TODO: This needs to be fixed with a better way. OLP resources have an application as olp, we can't use CONFIG.APPLICATION
                        // and not sure why requestData.application is sending in urh by default? need to check where and how this is set
                        // we can use the audit.iss from the resource to obtain the application, this will ensure we get the right one
  if (requestData.source === 'public' || requestData.source === 'department') {
    application = 'olp';
  } else {
    application = requestData.application || CONFIG.APPLICATION;
  }

  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          //application: requestData.application || CONFIG.APPLICATION,
          application,
          source,
          uid: uid,
          sessionid: sessionid
        })
      }
    };

    return axios.post(
      `${baseUrl}/resources/copy/${resourceId}?userId=${uid}&action=finalisecopy`,
      payload,
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function deleteResource(baseUrl, requestData) {
  const uid = requestData.uid;
  const firstName = requestData.firstName;
  const lastName = requestData.lastName;
  const resourceId = requestData.resourceId;
  const source = requestData.source || 'myresource';
  const application = requestData.application || CONFIG.APPLICATION;
  const subResources = requestData.subResources

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json',
        application,
        source,
        uid: uid,
        first_name: firstName,
        last_name: lastName
      })
    },
    data: {
      subResources
    }
  };

  return await axios.delete(
    `${baseUrl}/resources/resource/${resourceId}`,
    config
  );
}

export const patchResource = (url, data: any = {}, config = {}) =>
  patch(url, data, config);

export async function sendChanges(baseUrl, requestData) {
  const resourceId = requestData.resourceId;
  const payload = requestData.payload;

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json'
      })
    }
  };

  await axios.post(`${baseUrl}/resourceaudit/${resourceId}`, payload, config);
}

export async function editChanges(baseUrl, requestData) {
  const resourceId = requestData.resourceId;
  const payload = requestData.payload;
  const auditId = requestData.payload.auditId;

  delete payload.auditId;

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json'
      })
    }
  };

  await axios.put(`${baseUrl}/resourceaudit/${resourceId}?auditId=${auditId}`, payload, config);
}

export async function deleteChanges(baseUrl, requestData) {
  const resourceId = requestData.resourceId;
  const auditId = requestData.auditId;

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json'
      })
    }
  };

  await axios.delete(`${baseUrl}/resourceaudit/${resourceId}?auditId=${auditId}`, config);
}

export async function getVersionHistory(baseUrl, resourceId) {
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json'
      })
    }
  };

  return await axios.get(
    `${baseUrl}/resourceaudit/${resourceId}?sort=desc`,
    config
  );
}

export async function getNotifications(baseUrl, uid, source='myresource') {

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json',
        application: CONFIG.APPLICATION,
        source,
        uid: uid,
      })
    }
  };

  return await axios.get(`${baseUrl}/notifications/${uid}`, config);
}

export async function getResourceNotification(baseUrl, resourceId, source='myresource'){
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json',
        application: 'dlrh',
        source
      })
    }
  }
  return await axios.get(`${baseUrl}/notifications/none/${resourceId}`, config);
}

export async function deleteNotification(baseUrl, requestData) {
  const uid = requestData.uid;
  const notificationId = requestData.notificationId;
  const source = requestData.source || 'myresource';

  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        'Content-Type': 'application/json',
        application: 'dlrh',
        source,
        uid: uid,
      })
    }
  };

  return await axios.delete(`${baseUrl}/notifications/delete/${notificationId}`, config);
}

export async function revertPreviousVersion(baseUrl, payload) {
  const resourceId = payload.resourceId;
  const index = payload.index;
  const uid = payload.uid;
  const source = payload.source || 'myresource';

  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source,
          uid: uid
        })
      }
    };

    return await patch(
      `${baseUrl}/resources/resource/version/${resourceId}?action=revert&index=${index}`,
      {},
      config
    );
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Share my resource Card from myResources to colleague as "View Only"
 * @param baseUrl
 * @param data
 * @param config
 * @returns {Promise<AxiosResponse<any>|null>}
 */
export async function shareResourceCard(baseUrl, data={}, config = {}) {
  // console.log(data)
  try {
    return await axios.put(baseUrl, data, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}
