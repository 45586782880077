<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Content administration *</h3>
        <div class="body-1">
          This information is not visible in a published resource card
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-bind="$attrs"
          v-model="application"
          :items="$store.getters['users/profileApplication']"
          :readonly="!isNewResource"
          item-text="name"
          item-value="id"
          label="Application *"
          aria-label="Application"
          outlined
          required
          aria-required
          v-on="$listeners"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <IdentifierTextField
          v-model="ItemID"
          :disabled="!(isNewResource && application)"
          @validityChange="handleIdentifierValidityChange"
        />
      </v-col>
    </v-row>
    <v-row v-show="application === 'olp'">
      <v-col cols="12">
        <v-select
          v-model="scope"
          outlined
          required
          aria-label="Scope"
          aria-required
          label="Scope *"
          item-text="value"
          item-value="id"
          :items="$store.getters['metadata/scopes']"
          @change="handleDropdownChange('scope')"
        />
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <div class="quality-assuarance__container pa-4">
        <h3>
          Quality assurance
        </h3>
        <v-checkbox
          v-model.trim="qualityAssuredConfirmed"
          class="confirm-checkbox"
          label="I confirm the resource(s) associated have been quality assured against the agreed Department QA Process."
          @change="checkInputsValidationChange"
        />
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <AdsTextField
              v-model.trim="qualityAssuredBy"
              :label="`QA approved by (e.g. Director)${qualityAssuredConfirmed ? ' *' : ''}`"
              aria-label="QA approved by"
              :aria-required="qualityAssuredConfirmed"
              :required="qualityAssuredConfirmed"
              :disabled="!qualityAssuredConfirmed"
              :rules="[
                conditionallyRequiredRule(qualityAssuredConfirmed, 'Quality approved by', qualityAssuredBy)
              ]"
              @input="checkInputsValidationChange"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <DatePicker
              v-model="qualityAssuredDate"
              :label="`Date${qualityAssuredConfirmed ? ' *' : ''}`"
              aria-label="Date"
              :aria-required="qualityAssuredConfirmed"
              :required="qualityAssuredConfirmed"
              :disabled="!qualityAssuredConfirmed"
              :rules="[conditionallyRequiredRule(qualityAssuredConfirmed, 'Date', qualityAssuredDate)]"
              @input="checkInputsValidationChange"
            />
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import {AdsTextField, DatePicker} from '@nswdoe/doe-ui-core';
import IdentifierTextField from '@/views/ResourceEditorDetailsPage/Edit/IdentifierTextField';

export default {
  name: 'ContentAdmin',
  components: {
    AdsTextField,
    DatePicker,
    IdentifierTextField
  },
  data() {
    return {
      identifierInputValid: false,
      allDropdownsValid: false,
      allFieldsValid: false
    }
  },
  computed: {
    isNewResource() {
      return this.$route.params?.id === 'new';
    },
    validatedRequiredDropdowns() {
      return {
        application: this.application != null,
        scope: this.scope != null
      }
    },
    ItemID: {
      get() {
        return this.$attrs.document.ItemID;
      },
      set(val) {
        this.$attrs.document.ItemID = val;
      }
    },
    application: {
      get() {
        return this.$attrs.contentAdmin.application;
      },
      set(val) {
        this.$attrs.contentAdmin.application = val;
      }
    },
    identifier: {
      get() {
        return this.$attrs.contentAdmin.identifier;
      },
      set(val) {
        this.$attrs.contentAdmin.identifier = val;
      }
    },
    scope: {
      get() {
        return this.$attrs.contentAdmin.scope;
      },
      set(val) {
        this.$attrs.contentAdmin.scope = val;
      }
    },
    qualityAssuredConfirmed: {
      get() {
        return this.$attrs.contentAdmin.qualityAssuredConfirmed;
      },
      set(val) {
        this.$attrs.contentAdmin.qualityAssuredConfirmed = val;
      }
    },
    qualityAssuredBy: {
      get() {
        return this.$attrs.contentAdmin.qualityAssuredBy;
      },
      set(val) {
        this.$attrs.contentAdmin.qualityAssuredBy = val;
      }
    },
    qualityAssuredDate: {
      get() {
        return this.$attrs.contentAdmin.qualityAssuredDate;
      },
      set(val) {
        this.$attrs.contentAdmin.qualityAssuredDate = val;
      }
    }
  },
  methods: {
    conditionallyRequiredRule(condition, field, value) {
      return !condition || value?.length > 0 || `${field} is required`;
    },
    handleDropdownChange(name) {
      // Once a dropdown is valid it remains valid
      if (this.validatedRequiredDropdowns[name] === false && !this.allDropdownsValid) {
        this.validatedRequiredDropdowns[name] = true;
        if (Object.values(this.validatedRequiredDropdowns).findIndex(valid => !valid) < 0) {
          this.allDropdownsValid = true;
          this.checkInputsValidationChange();
        }
      }
    },
    handleIdentifierValidityChange(valid) {
      this.identifierInputValid = valid;
      this.checkInputsValidationChange();
    },
    checkInputsValidationChange() {
      // Only trigger when validation state changes
      const valid = this.allDropdownsValid && this.identifierInputValid && this.qualityAssuranceValid();
      if (this.allFieldsValid !== valid) {
        this.allFieldsValid = valid;
        this.$emit('validityChange', valid);
      }
    },
    qualityAssuranceValid() {
      return !this.data.qualityAssuredConfirmed || (
        this.data.qualityAssuredBy?.length > 0 && this.data.qualityAssuredDate?.length > 0
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.quality-assuarance__container {
  width: 100%;
  border: 1px solid $ads-light-40;
  border-radius: 4px;

  .confirm-checkbox::v-deep .v-input__slot {
    align-items: flex-start;
  }
}
</style>
